.ag-root-wrapper a{
  color: #0b50dc;
  font-size: 12px;
  text-decoration: none;
}
.ag-theme-material .ag-cell{
  font-size: 12px;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.ag-theme-material .ag-header-cell{
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.ag-root-wrapper a:hover {
  text-decoration: underline;
}
.ag-theme-material .ag-header{
  border-top: 1px solid #e2e2e2;
}
.ag-theme-material .ag-cell{
  color: #767676;
}
/* .full-row .ag-center-cols-container {
  width: 100% !important;
} */
.apps-group-table .ag-center-cols-container {
  width: 100% !important;
}

.ag-pinned-left-header {
  border-right: 0px !important;
}

.ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 32px !important;
}
